import { generateId } from '@utils/id'

export const pageText = {
  title: 'Basic Setup',
  description:
    'Start configuring the email server, updating the brand logo, changing the theme, customizing dashboards, and assigning duties to technicians based on their roles.',
  icon: 'sliders-h',
  color: '#3cc1c8',
  iconBackground: '#dff4f4',
}

export const availableSections = (availableModules) => [
  {
    key: generateId(),
    id: 'email',
    title: 'Support Email ',
    description: 'Primary Email Configuration.',
    sectionDetailTitle: 'Setting up support email',
    sectionDetailDescription:
      'Effortlessly set up the incoming and outgoing email servers.',
    steps: [
      {
        key: generateId(),
        id: 'incoming_email_config',
        title: 'Configuring incoming email server(s)',
        description: 'Determine the email server for incoming emails.',
        helpBubble: {
          icon: 'envelope',
          title: 'Incoming Email Server Settings',
          description:
            'You can add and test one or more incoming email servers to set up and receive emails that will be converted into incidents or requests.',
        },
        route: {
          text: 'Admin > Support Channel > Emails > Incoming Email Server',
          module: 'it-infrastructure',
          name: 'email-server-config',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'email',
                  step: 'incoming_email_config',
                  defaultData: {
                    currentTab: 'incoming',
                  },
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'outgoing_email_config',
        title: 'Configuring outgoing email server(s)',
        description: 'Determine the email server for outgoing emails.',
        helpBubble: {
          icon: 'envelope',
          title: 'Outgoing Email Server Settings',
          description:
            'You can add and test one or more outgoing email servers to set up the email ID from which the technicians or requesters will receive notifications over email.',
        },
        route: {
          text: 'Admin > Support Channel > Emails > Outgoing Email Server',
          module: 'it-infrastructure',
          name: 'email-server-config',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'email',
                  step: 'outgoing_email_config',
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'enable_email_to_ticket',
        title: 'Email Preferences for enabling Email to Ticket',
        description: 'Set up email preferences for converting Email to Ticket.',
        helpBubble: {
          icon: 'envelope',
          title: 'Convert Emails to Incidents/Requests',
          description:
            'Enable these self-explanatory features to allow the requesters to log an incident by simply emailing to one of the email servers configured in the previous section.',
        },
        route: {
          text: 'Admin > Support Channel > Emails > Email Preference',
          module: 'it-infrastructure',
          name: 'email-server-config',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'email',
                  step: 'enable_email_to_ticket',
                  defaultData: {
                    currentTab: 'emailpreference',
                  },
                })
              )
            ),
          },
        },
      },
    ],
  },
  {
    key: generateId(),
    id: 'branding',
    title: 'Rebranding and Theming',
    description: 'Personalize Your ServiceOps.',
    sectionDetailTitle: 'Rebranding ServiceOps and setting the UI colors',
    sectionDetailDescription:
      'From uploading your company logo to changing the name of the Support Portal, this section allows you to personalize ServiceOps.',
    steps: [
      {
        key: generateId(),
        id: 'branding_details',
        title: 'Details',
        description: `<ul><li>Rename HelpDesk and Technician and Support Portal.</li><li>Upload company logo and favicon.</li></ul>`,
        helpBubble: {
          icon: 'paint-brush',
          title: 'Logo and Portal Name Personalization',
          description:
            'These settings allow you to rename the HelpDesk portal and rebrand the application with your company logos.',
        },
        route: {
          text: 'Admin > Organization > Branding > Details',
          module: 'organization',
          name: 'branding',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'branding',
                  step: 'branding_details',
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'branding_theme',
        title: 'Theme',
        description: `<ul><li>Set a default color theme for Support and Technician Portals.</li><li>Change the look and feel of certain static pages.</li></ul>`,
        helpBubble: {
          icon: 'paint-brush',
          title: 'Color coordinate ServiceOps with your company’s theme',
          description:
            'Change the color scheme of Support and Technician portals in both light and dark modes. You can also choose from a list of pre-made themes. Preview the color theme before clicking the Update button.',
        },
        route: {
          text: 'Admin > Organization > Branding > Theme',
          module: 'organization',
          name: 'branding',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'branding',
                  step: 'branding_theme',
                  defaultData: {
                    currentTab: 'theme',
                  },
                })
              )
            ),
          },
        },
      },
    ],
  },
  {
    key: generateId(),
    id: 'user',
    title: 'Adding Technicians and associating Roles',
    description: 'Add technicians one by one or upload them in bulk',
    sectionDetailTitle: 'Technicians, Requesters and Roles',
    sectionDetailDescription:
      'Add Technicians to work on incidents or manage assets. Add Requesters (optional) to raise Service Requests or create incidents. Also, assign roles to technicians to specify their access to the ServiceOps.',
    steps: [
      {
        key: generateId(),
        id: 'add_new_technician',
        title: 'Adding new Technicians',
        description: `<ul><li>Create one technician at a time.</li><li>Bulk upload multiple technicians.</li></ul>`,
        helpBubble: {
          icon: 'user',
          title: 'Add Technicians',
          description:
            'Add technicians based on their Support Level, Location, Time Zone, Roles, and other criteria.',
        },
        route: {
          text: 'Admin > Users > Technicians',
          module: 'users',
          name: 'create',
          params: { userType: 'technician' },
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'user',
                  step: 'add_new_technician',
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'assign_technician_role',
        title: 'Assigning Technicians Roles',
        description: `<ul><li>Create and Assign roles to technicians.</li><li>Limit the technician's access to modules and other application configurations.</li></ul>`,
        helpBubble: {
          icon: 'user',
          title: 'Assign Roles',
          description:
            'Assign roles to technicians based on their Support Level, Location, Time Zone, Roles, and other criteria.',
        },
        route: {
          text: 'Admin > Users > Roles',
          module: 'roles',
          name: '',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'user',
                  step: 'assign_technician_role',
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'add_new_requester',
        title: 'Adding new Requesters',
        description: `<ul><li>Create one requester at a time.</li><li>Bulk upload multiple requesters.</li></ul>`,
        helpBubble: {
          icon: 'user',
          title: 'Add Requesters',
          description:
            'Add or import requesters. Assign them departments and mark them as VIPs.',
        },
        route: {
          text: 'Admin > Users > Requesters',
          module: 'users',
          name: '',
          params: { userType: 'requester' },
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'user',
                  step: 'add_new_requester',
                })
              )
            ),
          },
        },
      },
    ],
  },
  {
    key: generateId(),
    id: 'customizing_dashboard',
    title: 'Customizing Dashboard',
    description:
      'Dashboard presents a consolidated view of incident status, SLA, and asset inventory at a glance.',
    sectionDetailTitle: 'Create or Update Dashboards',
    sectionDetailDescription:
      'Dashboards provide you the information in graphical format and hosts essentials announcement.',
    steps: [
      {
        key: generateId(),
        id: 'managing_dashboard',
        title: 'Managing Dashboards',
        description: 'Manage all your dashboards.',
        helpBubble: {
          icon: 'tachometer-alt',
          title: 'Enable, Disable or Duplicate a Dashboard',
          description:
            'You can use the predefined dashboard out of the box. You can enable or disable them or simply click the copy button to create a duplicate dashboard. Then add or remove widgets as you like.',
        },
        route: {
          text: 'Dashboard > Manage Dashboard',
          module: 'dashboard',
          name: 'managedashboard',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'customizing_dashboard',
                  step: 'managing_dashboard',
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'adding_kpis',
        title: 'Adding KPIs',
        description: 'Create KPIs.',
        helpBubble: {
          icon: 'tachometer-alt',
          title: 'Add Key Performance Indicators (KPIs)',
          description:
            'KPIs indicate essential metrics to monitor the performance of your requests or incidents.',
        },
        route: {
          text: 'Dashboard > Customize > Add Widget > KPIs',
          module: 'dashboard',
          name: '',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'customizing_dashboard',
                  step: 'adding_kpis',
                  defaultData: {
                    customize: true,
                    type: 'kpi',
                  },
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'adding_widgets',
        title: 'Adding Widgets',
        description: 'Create Widgets.',
        helpBubble: {
          icon: 'tachometer-alt',
          title: 'Add Graphical or Grid Style Widgets',
          description:
            'Create widgets to display the top 5 categories receiving incidents, assets with missing patches, and more.',
        },
        route: {
          text: 'Dashboard > Customize > Add Widget > Widgets',
          module: 'dashboard',
          name: '',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'customizing_dashboard',
                  step: 'adding_widgets',
                  defaultData: {
                    customize: true,
                    type: 'widget',
                  },
                })
              )
            ),
          },
        },
      },
    ],
  },
]
