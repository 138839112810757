import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    meta: { moduleName, breadcrumbKey: 'service_catalog' },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "service-catalog" */ './views/list')
          ),
        meta: {},
      },
      {
        path: 'create',
        name: `${routeNamePrefix}.create`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "service-catalog" */ './views/create')
          ),
        meta: {},
      },
      {
        path: 'request-service',
        name: `${routeNamePrefix}.request`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "service-catalog" */ './views/list')
          ),
        meta: {},
      },
      {
        path: 'request-service/:id',
        name: `${routeNamePrefix}.request-service`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "service-catalog" */ './views/request-service'
            )
          ),
        meta: {},
      },
      {
        path: ':id',
        name: `${routeNamePrefix}.edit`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "service-catalog" */ './views/edit')
          ),
        meta: {},
      },
      {
        path: ':id/workflow/create',
        name: `${routeNamePrefix}.create-workflow`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "service-catalog" */ './views/create-workflow'
            )
          ),
        meta: {},
      },
      {
        path: ':id/sla/create',
        name: `${routeNamePrefix}.create-sla`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "service-catalog" */ './views/create-sla'
            )
          ),
        meta: {},
      },
      {
        path: ':id/approval/create',
        name: `${routeNamePrefix}.create-approval`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "service-catalog" */ './views/create-approval'
            )
          ),
        meta: {},
      },
      {
        path: ':id/scenario/create',
        name: `${routeNamePrefix}.create-scenario`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "service-catalog" */ './views/create-scenario'
            )
          ),
        meta: {},
      },
    ],
  },
]
