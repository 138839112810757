import constants from '@/src/constants/index'
import { generateId } from '@utils/id'

export const pageText = {
  title: 'Automation',
  description:
    'Automate request assignment, approval and create workflows to reduce manual intervention for repeated tasks.',
  icon: 'cogs',
  color: '#2549f2',
  iconBackground: '#bdc8fb',
}

export const availableSections = (availableModules) => [
  {
    key: generateId(),
    id: 'automation_setup',
    title: 'Auto Assignment',
    description: 'Select incident assignment strategy.',
    sectionDetailTitle: 'Automate Incident Assignment and Approvals',
    sectionDetailDescription:
      'The Smart Balance algorithm helps ease the workload on technicians, and automating repeated tasks saves a lot of manual labor.',
    steps: [
      {
        key: generateId(),
        id: 'auto_assignment_setup',
        title: 'Auto Assignment',
        description: 'Automatically assign tasks to technicians.',
        helpBubble: {
          icon: 'clipboard-list-check',
          title: 'Selecting Strategy to Auto Assign incidents',
          description:
            'Three unique strategies, including an AI-based incident assignment strategy, Smart Balance, are provided to assign incidents and requests to technicians automatically. Details about each strategy are provided here.',
        },
        route: {
          text: 'Admin > Automation > Auto Assignment',
          module: 'admin',
          name: 'auto-assignment',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'automation',
                  section: 'automation_setup',
                  step: 'auto_assignment_setup',
                })
              )
            ),
          },
        },
      },
    ],
  },
  {
    key: generateId(),
    id: 'workflow_setup',
    title: 'Workflows',
    description: 'Automatically evaluate a condition and perform actions.',
    sectionDetailTitle: 'No code automation workflows',
    sectionDetailDescription:
      'Create workflows to automate frequently repeating tasks without the need to write any code.',
    steps: [
      {
        key: generateId(),
        id: 'creating_workflow_setup',
        title: 'Automation through Workflows',
        description: 'Design the first workflow.',
        helpBubble: {
          icon: 'retweet',
          title: 'Your first event-based workflow',
          description: `<p>Based on the modules available, please follow the steps provide to create a workflow:</p>
          <b>Request Module</b>
          <ul>
          <li>Give your Workflow a name, select module as ‘Request’ and Workflow Type as ‘Event.’</li>
          <li>Under Trigger, set the trigger event as ‘Incident is Created.’ and click ‘Done.’ Next, click the ‘+’ button and select ‘Add Condition.’</li>
          <li>Select ‘Priority’ under Condition and ‘High’ under Value dropdown.</li>
          <li>Click ‘Done’ Next, click the ‘+’ button and select ‘Action for Yes’ Select ‘Set Impact to’ under Action and ‘On Users’ under the Value dropdown.</li>
          <li>Click ‘Done’,and Click on "Save Workflow" Voila! You have successfully created your first Request Workflow, which will set the impact as ‘On User’ of any incident created with a ‘High’ priority.</li></ul>
          <b>Asset Module</b>
          <ul>
          <li>Give your Workflow a name, select module as ‘Asset’, Workflow Type as ‘Event’ and Asset Type as ‘Hardware.’</li>
          <li>Under Trigger, set the trigger event as ‘Asset is Created’ and click ‘Done.’ Next, click the ‘+’ button and select ‘Action.’</li>
          <li>Select ‘Set Status to’ under Action and ‘In Stock’ under the Value dropdown.</li>
          <li>Click ‘Done’,and Click on 'Save Workflow' Voila! You have successfully created your first Asset Workflow, which will set the Asset</li>
          </ul>`,
        },
        route: {
          text: 'Admin > Automation > Workflow',
          module: 'workflow',
          name: '',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'automation',
                  section: 'workflow_setup',
                  step: 'creating_workflow_setup',
                })
              )
            ),
          },
        },
      },
    ],
  },
  {
    key: generateId(),
    id: 'approval_workflow_setup',
    title: 'Approval Workflows',
    description:
      'Triggers the approval requests when a technician asks for an approval.',
    sectionDetailTitle: 'Request for stakeholder consent',
    sectionDetailDescription:
      'Approval workflows make sure that no ticket moves forward without the consent of concerned stakeholders.',
    steps: [
      {
        key: generateId(),
        id: 'approval_workflow_setting_setup',
        title: 'Approval Workflow Settings',
        description:
          'Define approval setting for the global level in all modules.',
        helpBubble: {
          icon: 'bars',
          title: 'Setting the Global options for Approvals',
          description:
            'From selecting multi-approval decision type to allowing a user to create a manual approval, this section helps you select the required approval workflow settings. For more details, refer to this link.',
        },
        route: {
          text: 'Admin > Automation > Approval Workflow > Approval Setting',
          module: 'approval',
          name: '',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'automation',
                  section: 'approval_workflow_setup',
                  step: 'approval_workflow_setting_setup',
                  defaultData: {
                    approvalSettings: {
                      open: true,
                      currentModule: availableModules.includes(
                        constants.REQUEST
                      )
                        ? 'request'
                        : 'asset',
                    },
                  },
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'creating_approval_workflow_setup',
        title: 'Creating an Approval Workflow',
        description: 'Define the workflow for approval request.',
        helpBubble: {
          icon: 'check-double',
          title: 'Creating your first approval workflow',
          description:
            'This section lets you create module-specific unique approval workflows with multiple conditions and approval stages. Learn More about Approval Workflow creation by clicking this link.',
        },
        route: {
          text: 'Admin > Automation > Approval Workflow > Create Approval Workflow',
          module: 'approval',
          name: 'create',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'automation',
                  section: 'approval_workflow_setup',
                  step: 'creating_approval_workflow_setup',
                  defaultData: {
                    currentModule: availableModules.includes(constants.REQUEST)
                      ? 'request'
                      : 'asset',
                  },
                })
              )
            ),
          },
        },
      },
    ],
  },
]
