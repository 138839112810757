import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    meta: { moduleName },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "my-tasks" */ './views/list')
          ),
        meta: {},
      },
      {
        path: ':id',
        name: `${routeNamePrefix}.view`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "my-tasks" */ './views/view')
          ),
        meta: {},
      },
    ],
  },
]
