<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import { LicenseComputed } from '@state/modules/license'
import { CustomRulesMethods } from '@state/modules/custom-rules'
import { PreferenceComputed } from '@state/modules/preference'
import Constants from '@constants'
export default {
  name: 'ProjectModule',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.availableModulesInLicense.indexOf(Constants.PROJECT) === -1) {
        return vm.$router.replace({ name: 'upgrade-plan' })
      }
      if (vm.myAllowedModules.indexOf(Constants.PROJECT) === -1) {
        return vm.$router.replace({ name: '404' })
      }
      if (vm.projectFetchNoteRules) {
        vm.projectFetchNoteRules()
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
  },
  methods: {
    ...CustomRulesMethods,
  },
  page() {
    return {
      title: this.$t('Project'),
    }
  },
}
</script>
