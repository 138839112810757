import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { buildNameFilterQuery } from '@data/qualification'
import {
  transformRoleForList,
  transformRole,
  transformRoleForServer,
  transformAvailablePermissions,
} from '@data/role'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()

export function getSupportedPerissionsApi() {
  return api
    .get('/role/supportedpermissions')
    .then(transformAvailablePermissions)
}

export function getRolesApi(name, limit, offset, additionalUrlParams = {}) {
  return api
    .post(
      `role/search/byqual`,
      {
        qualDetails: name
          ? buildNameFilterQuery(name, 'equal_case_insensitive')
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionalUrlParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformRoleForList),
        total: data.totalCount,
      }
    })
}

export function createRoleApi(data) {
  return api.post(`/role`, transformRoleForServer(data), {
    message: __rootT('created_successfully', {
      resource: __rootTc('role'),
    }),
  })
}

export function getRoleApi(id) {
  return api.get(`role/${id}`).then(transformRole)
}

export function updateRoleApi(data) {
  return api.patch(`/role/${data.id}`, transformRoleForServer(data), {
    message: __rootT('updated_successfully', {
      resource: __rootTc('role'),
    }),
  })
}

export function deleteRoleApi(id) {
  return api.delete(`/role/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('role'),
    }),
  })
}
