import api from '@api'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformCompaniesForList,
  transformCompaniesForServer,
} from '@data/msp'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const getQualifications = (filters = {}) => {
  const qualDetails = []
  if ('companyIds' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        filters.companyIds,
        'long',
        'db'
      )
    )
  }
  if ('ids' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  return qualDetails
}

export function getCompaniesApi(filters, limit, offset, params = {}) {
  const qualDetails = [...getQualifications(filters)]
  return api
    .post(
      '/company/search/byqual',
      {
        qualDetails: buildFlatQualificationStructure(qualDetails),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformCompaniesForList) || [],
        total: data.totalCount,
      }
    })
}

export function createCompanyApi(data) {
  return api
    .post('/company', transformCompaniesForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('company'),
      }),
    })
    .then(transformCompaniesForList)
}

export function updateCompanyApi(data) {
  return api
    .patch(`/company/${data.id}`, transformCompaniesForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('company'),
      }),
    })
    .then(transformCompaniesForList)
}

export function deleteCompanyApi(id) {
  return api.delete(`/company/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('company'),
    }),
  })
}

export function autoDetectCompanyApi(email) {
  return api.get(`/company/autodetect/${email}`).then((data) => {
    return {
      companyId: data.id,
    }
  })
}
