import api from '@api'

import {
  // getModulePluaralTranslator,
  getRootTranslator,
} from '@utils/get-module-translator'
// import moduleConfig from './config'

import {
  transformTenantPrefrences,
  transformTenantPrefrencesForServer,
} from '@data/tenant-prefrences'

// const __tc = getModulePluaralTranslator(moduleConfig.translationKey)
const __rootT = getRootTranslator()

export function getTenantPrefrencesApi() {
  return api.get(`/tenantpreference`).then(transformTenantPrefrences)
}

export function updateTenantPrefrencesApi(data, sectionType) {
  return api
    .patch(`/tenantpreference`, transformTenantPrefrencesForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootT(sectionType || 'tenant_prefrences'),
      }),
    })
    .then(transformTenantPrefrences)
}

export function assetQRSupportDataApi() {
  return api.get(`/asset/qrcode/supportedData`).then((data) =>
    data.map((field) => ({
      key: field.key,
      name: field.name,
      required: field.required,
      customField: field.customField,
    }))
  )
}
export function assetBarcodeSupportDataApi() {
  return api.get(`/barcodeformatconfig/supportedData`).then((data) =>
    data.map((field) => ({
      key: field.key,
      name: field.name,
      required: field.required,
      customField: field.customField,
    }))
  )
}
