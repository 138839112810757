<template>
  <FlotoForm class="flex flex-col flex-1" @submit="handleSubmit">
    <FlotoFixedView>
      <MRow :gutter="0" class="px-4">
        <MCol :lg="8" :sm="10" auto-size>
          <FlotoPageHeader
            use-divider
            :back-link="
              $currentModule.getRoute('', {
                params: $route.params,
              })
            "
            :title="`${$t(kb.id ? 'edit' : 'create')} ${$t('knowledge')}`"
          >
            <template v-slot:after-title>
              <div class="inline-flex justify-end">
                <MButton
                  id="save-as-draft-update-draft-btn"
                  class="mx-1"
                  type="submit"
                  :loading="processing"
                >
                  {{
                    $tc(
                      kb.id
                        ? kb.status === 'published'
                          ? 'save_as_draft'
                          : 'update_draft'
                        : 'save_as_draft'
                    )
                  }}
                </MButton>
                <FlotoLink
                  id="cancel-btn"
                  :to="
                    $currentModule.getRoute('', {
                      params: $route.params,
                    })
                  "
                  as-button
                  variant="default"
                >
                  {{ $t('cancel') }}
                </FlotoLink>
              </div>
            </template>
          </FlotoPageHeader>
        </MCol>
      </MRow>
      <FlotoScrollView>
        <MRow :gutter="0" class="px-4">
          <MCol :lg="8" :sm="10" auto-size>
            <FlotoFormItem
              id="title-input"
              v-model="kb.subject"
              :placeholder="$t('title')"
              :label="$t('title')"
              rules="required|subject"
            />
            <FlotoFormItem
              id="folder-picker"
              :label="$tc('folder')"
              rules="required"
            >
              <FolderPicker v-model="kb.folderId" class="w-1/2" />
            </FlotoFormItem>
            <FlotoFormItem id="attachment-btn" :label="$tc('attachment', 2)">
              <FlotoAttachment v-model="kb.fileAttachments" use-public-api />
            </FlotoFormItem>
            <FlotoFormItem id="tags-picker" :label="$tc('tag', 2)">
              <FlotoTagsPicker v-model="kb.tags" />
            </FlotoFormItem>
            <FlotoFormItem
              id="content-input"
              :label="$tc('content')"
              rules="required"
            >
              <FlotoRichEditor
                v-model="kb.content"
                use-public-api
                :min-rows="8"
                :placeholder="$tc('content')"
              />
            </FlotoFormItem>
          </MCol>
        </MRow>
      </FlotoScrollView>
    </FlotoFixedView>
    <template v-slot:submit>
      <span />
    </template>
  </FlotoForm>
</template>

<script>
import { DraftPublishedStatusOptions } from '@utils/status'
import FolderPicker from './folder-picker'

export default {
  name: 'KnowledgeForm',
  components: { FolderPicker },
  props: {
    defaultValue: {
      type: Object,
      default() {
        return {}
      },
    },
    processing: { type: Boolean, default: false },
  },
  data() {
    this.statusOptions = DraftPublishedStatusOptions()
    return {
      kb: { ...this.defaultValue },
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.kb)
    },
  },
}
</script>
