import { generateId } from '@utils/id'

export const pageText = {
  title: 'SLA',
  description:
    'Set up custom business hours to align with multiple shifts in your Organization. Also, create SLAs based on different criteria and escalation levels.',
  icon: 'file-alt',
  color: '#fab81f',
  iconBackground: '#FAEDCD',
}

export const availableSections = (availableModules) => [
  {
    key: generateId(),
    id: 'business_hours',
    title: 'Business Hours',
    description: 'Set up the custom work hours as per your organization.',
    sectionDetailTitle: 'Setting Up Business Hours and Configuring SLA',
    sectionDetailDescription:
      'Create and enable custom Business Hours and Service Level Agreements.',
    steps: [
      {
        key: generateId(),
        id: 'sla_business_hours',
        title: 'Business Hours',
        description: 'Add active working hours.',
        helpBubble: {
          icon: 'business-time',
          title: 'Add Business Hours and Holidays',
          description:
            'Click on the ‘Add Business Hour’ button to create active working hours that suit your Organization’s requirements. Also, add holidays to ensure SLAs do not trigger during operational hours on these days.',
        },
        route: {
          text: 'Admin > Organization > Business Hours',
          module: 'business-hours',
          name: 'create',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'sla',
                  section: 'business_hours',
                  step: 'sla_business_hours',
                })
              )
            ),
          },
        },
      },
    ],
  },
  {
    key: generateId(),
    id: 'sla_guide',
    title: 'Configuring SLA',
    description:
      'Create Service Level Agreements to ensure quick and on-time incident resolution',
    sectionDetailTitle: 'Setting Up Business Hours and Configuring SLA',
    sectionDetailDescription:
      'Create and enable custom Business Hours and Service Level Agreements.',
    steps: [
      {
        key: generateId(),
        id: 'configuring_sla',
        title: 'Configuring SLA',
        description: 'Modify or create SLA.',
        helpBubble: {
          icon: 'file-alt',
          title: 'Modify or Create Service Level Agreements',
          description:
            'We offer four out-of-the-box SLAs for you. You can modify them to suit your preferences or create a new SLA set by adding triggering conditions, response time, resolution time, and escalation levels.',
        },
        route: {
          text: 'Admin > Automation > SLA',
          module: 'sla',
          name: 'create',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'sla',
                  section: 'sla_guide',
                  step: 'configuring_sla',
                })
              )
            ),
          },
        },
      },
    ],
  },
]
